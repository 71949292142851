@import "tt-variables";

@font-face {
  font-family: "TelltaleIcons";
  src: url("#{$di-font-path}/telltaleicons.eot?v=#{$di-version}");
  src: url("#{$di-font-path}/telltaleicons.eot?v=#{$di-version}#iefix") format("embedded-opentype"), url("#{$di-font-path}/telltaleicons.woff2?v=#{$di-version}") format("woff2"), url("#{$di-font-path}/telltaleicons.woff?v=#{$di-version}") format("woff"),
    url("#{$di-font-path}/telltaleicons.ttf?v=#{$di-version}") format("truetype"), url("#{$di-font-path}/telltaleicons.svg?v=#{$di-version}#telltaleicons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.telltaleicons {
  display: inline-block;
  font-family: "TelltaleIcons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import "tt-common";
@import "tt-icons";
