@charset "UTF-8";
// Directed Telltale Icons Icon Font CSS
// --------------------------

.tt-02:before,.tt-02-High_MainBeam:before { content: "\f11b"; }
.tt-04:before,.tt-04-TurnSignal:before { content: "\f119"; }
.tt-05:before,.tt-05-HazardWarning:before { content: "\f124"; }
.tt-07:before,.tt-07-ParkingBrake:before { content: "\f123"; }
.tt-08:before,.tt-08-BrakeFailure:before { content: "\f105"; }
.tt-10:before,.tt-10-FuelLevel:before { content: "\f113"; }
.tt-11:before,.tt-11-EngineCoolantTemp:before { content: "\f121"; }
.tt-12:before,.tt-12-BatteryChargingCondition:before { content: "\f116"; }
.tt-13:before,.tt-13-EngineOil:before { content: "\f108"; }
.tt-14:before,.tt-14-PositionLights_SideLights:before { content: "\f118"; }
.tt-15:before,.tt-15-FrontFogLights:before { content: "\f106"; }
.tt-16:before,.tt-16-RearFogLights:before { content: "\f115"; }
.tt-17:before,.tt-17-ParkHeating:before { content: "\f103"; }
.tt-18:before,.tt-18-Engine_MilIndicator:before { content: "\f129"; }
.tt-21:before,.tt-21-TransmissionFailure_Malfunction:before { content: "\f10d"; }
.tt-22:before,.tt-22-ABSystemFailure:before { content: "\f127"; }
.tt-23:before,.tt-23-WornBrakeLinings:before { content: "\f122"; }
.tt-24:before,.tt-24-WindscreenWasherFluid:before { content: "\f102"; }
.tt-25:before,.tt-25-TPMS:before { content: "\f10e"; }
.tt-26:before,.tt-26-Malfunction_GeneralFailure:before { content: "\f104"; }
.tt-27:before,.tt-27-EngineOilTemp:before { content: "\f11d"; }
.tt-28:before,.tt-28-EngineOilLevel:before { content: "\f10c"; }
.tt-29:before,.tt-29-EngineCoolantLevel:before { content: "\f114"; }
.tt-30:before,.tt-30-SteeringFluidLevel:before { content: "\f125"; }
.tt-31:before,.tt-31-SteeringFailure:before { content: "\f10b"; }
.tt-32:before,.tt-32-HeightControl:before { content: "\f11a"; }
.tt-33:before,.tt-33-Retarder:before { content: "\f112"; }
.tt-34:before,.tt-34-EngineEmissionSystemFailure:before { content: "\f11e"; }
.tt-35:before,.tt-35-ESCIndication:before { content: "\f111"; }
.tt-38:before,.tt-38-StopRequest:before { content: "\f11c"; }
.tt-41:before,.tt-41-AdBlueLevel:before { content: "\f11f"; }
.tt-47:before,.tt-47-AirFilterClogged:before { content: "\f126"; }
.tt-48:before,.tt-48-FuelFilterDifferentialPressure:before { content: "\f10f"; }
.tt-49:before,.tt-49-SeatBelt:before { content: "\f101"; }
.tt-50:before,.tt-50-EBS:before { content: "\f110"; }
.tt-51:before,.tt-51-LaneDepartureIndication:before { content: "\f128"; }
.tt-52:before,.tt-52-AdvancedEmergencyBrakingSystem:before { content: "\f117"; }
.tt-53:before,.tt-53-ACC:before { content: "\f107"; }
.tt-55:before,.tt-55-ABSTrailer1_2Failure_Fault:before { content: "\f109"; }
.tt-57:before,.tt-57-EBSTrailer1_2:before { content: "\f100"; }
.tt-58:before,.tt-58-TachographIndication:before { content: "\f120"; }
.tt-59:before,.tt-59-ESCSwitchedOff:before { content: "\f10a"; }